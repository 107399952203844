export const en = {
  brimarexCode: 'Brimarex Code',
  producerCode: 'Producer Code',
  ean: 'EAN',
  name: 'Name',
  price: 'Price',
  priceWithVat: 'Gross price',
  availableQuantity: 'Available Qty.',
  quantity: 'Quantity',
  image: 'Image',
  quantityInPackage: 'pcs. in package',
  producer: 'Producer',
  additionalFilters: 'Additional',
  displaySettings: 'Display',
  onlyAvailable: 'Only Available',
  onlyNew: 'Only New',
  onlyTop: 'Only Top',
  onlyPromo: 'Only Special Offers',
  onlySelected: 'Only Selcted',
  displayImages: 'Image',
  displayDescriptions: 'Description',
  displayProducerCode: 'Producer Code',
  displayEan: 'EAN',
  order: 'Order',
  belowYouCanFindOrderSummary: 'Below you can find your order summary.',
  afterOrderCreationTraderWillContactYou: 'After order save, our Sales Representative will contact you as soon as possible to confirm all details',
  savingOrder: 'Saving order...',
  orderSaved: 'Order saved! Remember, that you can use this offer many times and create many orders',
  value: 'Value',
  valueWithVat: 'Gross Value',
  selectedQuantityHigherThanAvailable: 'Selected higher quantity than available.',
  commentsToOrder: 'Comments to order',
  createOrder: 'Create order',
  rowsPerPage: 'Rows per page',
  overall: 'overall',
  of: 'of',
  search: 'Search',
  all: 'All',
  categories: 'Categories'
};
