import React, {useState} from 'react';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {formatPrice} from "../../utils/formatPrice";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import {ICart} from "../../hooks/useCart";
import {OfferProductType, OfferTypeEnum} from "../../api/offer/offer.generated";
import {useCreatePurchaseRequestMutation} from "../../api/createPurchaseRequest/createPurchaseRequest.generated";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import {useTranslation} from "react-i18next";
import {DeliveryForm, DeliveryFormState} from "../delivery-form/DeliveryForm";
import {ProductQuantityInput} from "../product-quantity-input/ProductQuantityInput";

const useStyles = makeStyles({
  additionalInfoItem: {
    padding: 10,
  },
});

interface CartButtonProps {
  offerId: string;
  type: OfferTypeEnum;
  code: string;
  cart: ICart;
  products: Array<Partial<OfferProductType>>;
  setCart: (cart: ICart) => any;
  onCartChange: (productId: string, quantity: number) => any;
}

export const CartButton = (props: CartButtonProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [isSendInProgress, setSendInProgress] = useState(false);
  const [isSendFinished, setSendFinished] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [deliveryForm, setDeliveryForm] = useState<DeliveryFormState>({
    fullName: '',
    address: '',
    postCode: '',
    city: '',
    email: '',
    phoneNumber: '',
  });
  const [createPurchaseRequestMutation] = useCreatePurchaseRequestMutation();
  const toggleDialog = () => {
    setIsDialogOpened(!isDialogOpened);
    if (isSendFinished) {
      setTimeout(() => {
        setSendFinished(false);
      }, 500);
    }
  };

  const cartValue = Object.entries(props.cart.products).reduce((acc, [productId, quantity]) => {
    const product = props.products.find((p) => p.id === productId);
    if (!product) {
      return acc;
    }
    return acc + (product.priceDiscounted || 0) * quantity;
  }, 0);

  const logisticsMinimumValue = 100000;
  const isLogisticsMinimumReached: boolean = cartValue >= logisticsMinimumValue;

  const createPurchaseRequest = async () => {
    try {
      setSendInProgress(true);
      createPurchaseRequestMutation({
        variables: {
          input: {
            offerId: props.offerId,
            code: props.code,
            additionalInfo,
            deliveryAddress: props.type === OfferTypeEnum.Dropshipping ? deliveryForm : undefined,
          },
        },
      }).then(() => {
        setSendFinished(true);
        setSendInProgress(false);
        props.setCart({
          products: {},
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        size={'large'}
        variant={cartValue > 0 ? 'contained' : 'outlined'}
        startIcon={<ShoppingCartIcon />}
        color={'primary'}
        onClick={toggleDialog}
      >{formatPrice(cartValue)}</Button>
      <Dialog
        open={isDialogOpened}
        onClose={toggleDialog}
        maxWidth={'md'}
      >
        <DialogTitle>{t('order')}</DialogTitle>
        <DialogContent dividers>
          <Typography>{t('belowYouCanFindOrderSummary')}</Typography>
          <Typography>{t('afterOrderCreationTraderWillContactYou')}</Typography>
        </DialogContent>
        <DialogContent dividers>
          {(() => {
            if (isSendInProgress) {
              return (
                <Grid container>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography>{t('savingOrder')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LinearProgress color={'secondary'} />
                  </Grid>
                </Grid>
              );
            }

            if (isSendFinished) {
              return (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography style={{textAlign: 'center'}}>
                      <DoneOutlineIcon
                        color={'secondary'}
                        fontSize={'large'}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography>{t('orderSaved')}</Typography>
                  </Grid>
                </Grid>
              );
            }

            return (
              <Grid container>
                <Grid item xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{width: 90}}>{t('brimarexCode')}</TableCell>
                        <TableCell>{t('name')}</TableCell>
                        <TableCell style={{width: 60, textAlign: 'center'}}>{t('quantity')}</TableCell>
                        <TableCell style={{width: 100, textAlign: 'right'}}>{t('value')}</TableCell>
                        <TableCell style={{width: 100, textAlign: 'right'}}>{t('valueWithVat')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(props.cart.products).filter(([productId, quantity]) => quantity > 0).map(([productId, quantity], index) => {
                        const product = props.products.find((p) => p.id === productId);
                        if (!product) {
                          return null;
                        }
                        const isSelectedQuantityHigherThanAvailable: boolean = product.quantity && product.quantity.includes('+')
                          ? false
                          : Number(product.quantity) < quantity;
                        return (
                          <TableRow key={`row_${index}`}>
                            <TableCell>{product.brimarexCode}</TableCell>
                            <TableCell>
                              <Typography
                                variant={'subtitle2'}
                                display={'block'}
                              >{product.name}</Typography>
                              {isSelectedQuantityHigherThanAvailable && (
                                <Typography
                                  variant={'caption'}
                                  display={'block'}
                                  color={'secondary'}
                                >{t('selectedQuantityHigherThanAvailable')}</Typography>
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: 'center',
                                color: isSelectedQuantityHigherThanAvailable ? 'red' : undefined,
                              }}
                            >
                              <ProductQuantityInput
                                availableQuantity={product.quantity as string}
                                quantityInCart={quantity}
                                quantityInPackage={product.quantityInPackage || 1}
                                setQuantityInCart={(quantity) => props.onCartChange(product.id as string, quantity)}
                                isRemovable
                              />
                            </TableCell>
                            <TableCell style={{textAlign: 'right'}}>{formatPrice((product.priceDiscounted || 0) * quantity)}</TableCell>
                            <TableCell style={{textAlign: 'right'}}>{formatPrice((product.priceDiscounted || 0) * quantity * 1.23)}</TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell style={{textAlign: 'right'}}>{formatPrice(cartValue)}</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{formatPrice(cartValue * 1.23)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>

                <DeliveryForm
                  isLogisticsMinimumReached={isLogisticsMinimumReached}
                  logisticsMinimumValue={logisticsMinimumValue}
                  type={props.type}
                  value={deliveryForm}
                  onChange={(data: DeliveryFormState) => setDeliveryForm(data)}
                />

                <Grid item xs={12} className={styles.additionalInfoItem}>
                  <TextField
                    multiline={true}
                    rows={4}
                    label={t('commentsToOrder')}
                    fullWidth={true}
                    variant={'outlined'}
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value as string)}
                  />
                </Grid>
              </Grid>
            );
          })()}
        </DialogContent>
        {!isSendInProgress && !isSendFinished && (
          <DialogActions>
            <Button
              autoFocus
              color={'primary'}
              onClick={createPurchaseRequest}
            >{t('createOrder')}</Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
