import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { OfferTable, OfferTableOrderEnum } from "./OfferTable";
import {
  IOfferDisplayConfig,
  IOfferFilters,
  OfferFilters,
} from "./OfferFilters";
import Logo from "../../logo.svg";
import { CartButton } from "../order/CartButton";
import { ICart } from "../../hooks/useCart";
import {
  OfferHistoryLogTypeEnum,
  OfferProductType,
  OfferTypeEnum,
  useOfferQuery,
} from "../../api/offer/offer.generated";
import { useAppendOfferHistoryLogMutation } from "../../api/appendOfferHistoryLog/appendOfferHistoryLog.generated";
import { usePutProductInCartMutation } from "../../api/putProductInCart/putProductInCart.generated";
import { Link } from "react-router-dom";
import { CookieInfo } from "../cookie-info/CookieInfo";
import { useUrlQuery } from "../../hooks/useUrlQuery";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "../../translations/en";
import { pl } from "../../translations/pl";
import { ChangeLanguageButton } from "../change-language-button/ChangeLanguageButton";
import { SearchInput } from "../search-input/SearchInput";
import CategoryTree from "../category-tree/CategoryTree";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    pl: {
      translation: pl,
    },
  },
  lng: "pl",
  fallbackLng: "pl",
});

export const Offer = () => {
  const urlQuery = useUrlQuery();

  const query = {
    id: urlQuery.content.id,
    code: urlQuery.content.code,
  };

  const [cart, setCart] = useState<ICart>({
    products: {},
  });

  const [productCategories, setProductCategories] = useState<
    any[]
  >([]);

  const [offerNotFound, setOfferNotFound] = useState(false);

  const [products, setProducts] = useState<Array<Partial<OfferProductType>>>(
    []
  );

  const [filters, setFilters] = useState<IOfferFilters>({
    categories:
      typeof urlQuery.content.categories === "string"
        ? [urlQuery.content.categories]
        : Array.isArray(urlQuery.content.categories)
        ? urlQuery.content.categories
        : [],
    onlyAvailable: urlQuery.content.onlyAvailable === "true",
    onlyNew: urlQuery.content.onlyNew === "true",
    onlyTop: urlQuery.content.onlyTop === "true",
    onlyPromo: urlQuery.content.onlyPromo === "true",
    onlySelected: urlQuery.content.onlySelected === "true",
  });

  const updateFilters = (data: IOfferFilters) => {
    setFilters(data);
    urlQuery.update({
      categories: data.categories,
      onlyAvailable: data.onlyAvailable ? "true" : "false",
      onlyNew: data.onlyNew ? "true" : "false",
      onlyTop: data.onlyTop ? "true" : "false",
      onlyPromo: data.onlyPromo ? "true" : "false",
      onlySelected: data.onlySelected ? "true" : "false",
    });
  };

  const [displayConfig, setDisplayConfig] = useState<IOfferDisplayConfig>({
    displayImages:
      urlQuery.content.displayImages !== undefined
        ? urlQuery.content.displayImages === "true"
        : true,
    displayDescriptions:
      urlQuery.content.displayDescriptions === "true" || false,
    displayProducerCode:
      urlQuery.content.displayProducerCode === "true" || false,
    displayEan: urlQuery.content.displayEan === "true" || false,
  });

  const updateDisplayConfig = (config: IOfferDisplayConfig) => {
    setDisplayConfig(config);
    urlQuery.update({
      displayImages: config.displayImages ? "true" : "false",
      displayDescriptions: config.displayDescriptions ? "true" : "false",
      displayProducerCode: config.displayProducerCode ? "true" : "false",
      displayEan: config.displayEan ? "true" : "false",
    });
  };

  const [order, setOrder] = useState(OfferTableOrderEnum.BrimarexCodeAsc);

  const [page, setPage] = useState(0);

  const [appendOfferHistoryLogMutation] = useAppendOfferHistoryLogMutation();

  const [putProductInCart] = usePutProductInCartMutation();

  const [searchPhrase, setSearchPhrase] = useState<string>("");

  const offerQuery = useOfferQuery({
    variables: {
      id: query.id as string,
      code: query.code as string,
      searchPhrase: searchPhrase || undefined,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      setCart({
        products: result.offer.cart.products.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.productId]: cur.quantity,
          }),
          {}
        ),
      });

      setProducts(result.offer.products);

      setProductCategories(result.productCategories);

      setFilters({
        ...filters,
        categories:
          filters.categories.length === 0
            ? result.productCategories.map((c) => c.id)
            : filters.categories,
      });
    },
    onError: () => {
      setOfferNotFound(true);
    },
  });
  const onSearchClick = (newSearchPhrase: string) => {
    setSearchPhrase(newSearchPhrase);
  };

  useEffect(() => {
    appendOfferHistoryLogMutation({
      variables: {
        code: query.code as string,
        offerId: query.id as string,
        logType: OfferHistoryLogTypeEnum.Open,
      },
    }).catch(() => {});

    const onBeforeUnload = () => {
      appendOfferHistoryLogMutation({
        variables: {
          code: query.code as string,
          offerId: query.id as string,
          logType: OfferHistoryLogTypeEnum.Close,
        },
      });
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [appendOfferHistoryLogMutation, query.id, query.code]);

  if (
    !products.length &&
    !offerQuery.loading &&
    offerQuery.data &&
    offerQuery.data.offer.products.length
  ) {
    setProducts(offerQuery.data.offer.products);
  }

  let productsFiltered = products.filter((p) =>
    filters.categories.includes(p.categoryId as string)
  );

  if (filters.onlyAvailable) {
    productsFiltered = productsFiltered.filter(
      (c) => c.quantity && c.quantity !== "0"
    );
  }

  if (filters.onlyNew) {
    productsFiltered = productsFiltered.filter((c) => c.isNew);
  }

  if (filters.onlyTop) {
    productsFiltered = productsFiltered.filter((c) => c.isTop);
  }

  if (filters.onlySelected) {
    productsFiltered = productsFiltered.filter(
      (c) =>
        typeof cart.products[c.id as string] === "number" &&
        cart.products[c.id as string] > 0
    );
  }

  if (filters.onlyPromo) {
    productsFiltered = productsFiltered.filter((c) => c.isPricingPriceFinal);
  }

  switch (order) {
    case OfferTableOrderEnum.BrimarexCodeAsc:
      productsFiltered = productsFiltered.slice().sort((l, r) => {
        if ((l.brimarexCode as string) > (r.brimarexCode as string)) {
          return 1;
        } else if ((l.brimarexCode as string) < (r.brimarexCode as string)) {
          return -1;
        } else {
          return 0;
        }
      });
      break;
    case OfferTableOrderEnum.BrimarexCodeDesc:
      productsFiltered = productsFiltered.slice().sort((l, r) => {
        if ((l.brimarexCode as string) > (r.brimarexCode as string)) {
          return -1;
        } else if ((l.brimarexCode as string) < (r.brimarexCode as string)) {
          return 1;
        } else {
          return 0;
        }
      });
      break;
    case OfferTableOrderEnum.NameAsc:
      productsFiltered = productsFiltered.slice().sort((l, r) => {
        if ((l.name as string) > (r.name as string)) {
          return 1;
        } else if ((l.name as string) < (r.name as string)) {
          return -1;
        } else {
          return 0;
        }
      });
      break;
    case OfferTableOrderEnum.NameDesc:
      productsFiltered = productsFiltered.slice().sort((l, r) => {
        if ((l.name as string) > (r.name as string)) {
          return -1;
        } else if ((l.name as string) < (r.name as string)) {
          return 1;
        } else {
          return 0;
        }
      });
      break;
    case OfferTableOrderEnum.PriceAsc:
      productsFiltered = productsFiltered.slice().sort((l, r) => {
        if ((l.price as number) > (r.price as number)) {
          return 1;
        } else if ((l.price as number) < (r.price as number)) {
          return -1;
        } else {
          return 0;
        }
      });
      break;
    case OfferTableOrderEnum.PriceDesc:
      productsFiltered = productsFiltered.slice().sort((l, r) => {
        if ((l.price as number) > (r.price as number)) {
          return -1;
        } else if ((l.price as number) < (r.price as number)) {
          return 1;
        } else {
          return 0;
        }
      });
      break;
  }

  const updateCart = (productId: string, quantity: number) => {
    putProductInCart({
      variables: {
        offerId: query.id as string,
        code: query.code as string,
        productId,
        quantity,
      },
    });
    setCart({
      ...cart,
      products: {
        ...cart.products,
        [productId]: quantity < 0 ? 0 : quantity,
      },
    });
  };

  const handleOnFiltersChange = (updatedFilters: IOfferFilters) => {
    setPage(0);
    updateFilters(updatedFilters);
  };

  if (!products.length && offerQuery.loading) {
    return (
      <Grid
        container
        justify={"center"}
        direction={"column"}
        alignItems={"center"}
      >
        <Grid item xs={12} md={6}>
          <img src={Logo} alt={"Brimarex logo"} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>
            Trwa ładowanie oferty, dziękujemy za cierpliwość
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            padding: 20,
          }}
        >
          <CircularProgress color={"secondary"} />
        </Grid>
      </Grid>
    );
  }

  if (offerNotFound) {
    return (
      <Grid
        container
        justify={"center"}
        direction={"column"}
        alignItems={"center"}
      >
        <Grid item xs={12} md={6}>
          <img src={Logo} alt={"Brimarex logo"} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>Oferta nie została znaleziona</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Link to={"/"}>
            <Typography>
              Wypełnij formularz kontaktowy aby uzyskać dostęp do oferty
            </Typography>
          </Link>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container style={{ padding: 10 }}>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4}>
          <img src={Logo} alt={"Brimarex logo"} />
        </Grid>
        <Grid item xs={12} md={4}>
          <SearchInput
            defaultValue={searchPhrase}
            onSearchClick={onSearchClick}
            disabled={offerQuery.loading}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ textAlign: "right" }}>
          <ChangeLanguageButton />
          <CartButton
            offerId={query.id as string}
            code={query.code as string}
            type={offerQuery.data?.offer.type || OfferTypeEnum.Standard}
            cart={cart}
            products={products}
            setCart={setCart}
            onCartChange={updateCart}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={2} style={{ padding: 10 }}>
        <CategoryTree
          productCategories={productCategories}
          filters={filters}
          onFiltersChange={handleOnFiltersChange}
        />
        <OfferFilters
          productCategories={productCategories}
          filters={filters}
          onFiltersChange={handleOnFiltersChange}
          displayConfig={displayConfig}
          onDisplayConfigChange={updateDisplayConfig}
        />
      </Grid>
      <Grid item xs={12} md={10} style={{ padding: 10 }}>
        <OfferTable
          cart={cart}
          filters={filters}
          displayConfig={displayConfig}
          onCartChange={updateCart}
          products={productsFiltered}
          allProductsCount={products.length}
          order={order}
          setOrder={setOrder}
          page={page}
          setPage={setPage}
        />
      </Grid>
      <CookieInfo />
    </Grid>
  );
};
