import React from 'react';
import {Button, IconButton, InputAdornment, OutlinedInput, Typography} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import {useTranslation} from "react-i18next";
import Delete from "@material-ui/icons/Delete";

interface ProductQuantityInputProps {
  availableQuantity: string;
  quantityInCart: number;
  quantityInPackage: number;
  setQuantityInCart: (quantity: number) => any;
  isRemovable?: boolean
}

export const ProductQuantityInput = (props: ProductQuantityInputProps) => {
  const { t } = useTranslation();

  const isPackageNumberIncorrect = (props.quantityInCart % props.quantityInPackage) !== 0;

  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <OutlinedInput
          startAdornment={
            <InputAdornment position={'end'}>
              <IconButton onClick={() => props.setQuantityInCart(props.quantityInCart - props.quantityInPackage)}>
                <RemoveIcon />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position={'end'}>
              <IconButton onClick={() => props.setQuantityInCart(props.quantityInCart + props.quantityInPackage)}>
                <AddIcon />
              </IconButton>
            </InputAdornment>
          }
          value={props.quantityInCart}
          inputProps={{
            style: {
              textAlign: 'center',
              minInlineSize: '25px',
              paddingLeft: '18.5px'
            },
          }}
          onChange={(e) => props.setQuantityInCart(Number(e.target.value))}
          type={'number'}
          error={(
            isPackageNumberIncorrect || (props.availableQuantity && !props.availableQuantity.includes('+')
              ? props.quantityInCart > Number(props.availableQuantity)
              : false)
          )}
        />
        {props.isRemovable ?
        <Button onClick={() => props.setQuantityInCart(0)}><Delete style={{color: 'red', width: '16px'}}/></Button> : null
        }
      </div>
      {props.quantityInPackage > 1 && (
        <Typography
          variant={'overline'}
          display={'block'}
          style={{
            textAlign: 'center',
            color: isPackageNumberIncorrect ? 'red' : undefined,
          }}
        >{`${props.quantityInPackage} ${t('quantityInPackage')}`}</Typography>
      )}
    </>
  );
};
