import React, {useState} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {buildImageUrl} from "../../utils/buildImageUrl";

interface ProductImageProps {
  mainImageKey: string;
  imagesKeys: string[];
  name: string;
}

export const ProductImage = (props: ProductImageProps) => {
  const [ lightboxOpened, setLightboxOpened ] = useState(false);

  const mainImageIndex = props.imagesKeys.indexOf(props.mainImageKey);

  const [ currentImageIndex, setCurrentImageIndex ] = useState(mainImageIndex);

  const currentImageKey = props.imagesKeys[currentImageIndex];

  const imageUrl = buildImageUrl(currentImageKey);

  const nextImageIndex = currentImageIndex + 1 < props.imagesKeys.length ? currentImageIndex + 1 : 0;

  const prevImageIndex = currentImageIndex - 1 >= 0 ? currentImageIndex - 1 : props.imagesKeys.length - 1;

  const nextImageUrl = props.imagesKeys.length > 1
    ? buildImageUrl(props.imagesKeys[nextImageIndex])
    : undefined;

  const prevImageUrl = props.imagesKeys.length > 1
    ? buildImageUrl(props.imagesKeys[prevImageIndex])
    : undefined;

  return (
    <>
      <img
        src={buildImageUrl(props.mainImageKey)}
        style={{
          width: '90%',
        }}
        alt={props.name}
        onClick={() => setLightboxOpened(true)}
      />
      {lightboxOpened && (
        <Lightbox
          mainSrc={imageUrl}
          mainSrcThumbnail={imageUrl}
          onCloseRequest={() => {
            setLightboxOpened(false);
            setCurrentImageIndex(mainImageIndex)
          }}
          nextSrc={nextImageUrl}
          nextSrcThumbnail={nextImageUrl}
          prevSrc={prevImageUrl}
          prevSrcThumbnail={prevImageUrl}
          onMoveNextRequest={() => setCurrentImageIndex(nextImageIndex)}
          onMovePrevRequest={() => setCurrentImageIndex(prevImageIndex)}
        />
      )}
    </>
  );
};
