import {useHistory} from "react-router-dom";
import QueryString from 'query-string';

type QueryStringContent = {
  lang?: string;

  id?: string;
  code?: string;

  categories?: string | string[];
  onlyAvailable?: string;
  onlyNew?: string;
  onlyTop?: string;
  onlyPromo?: string;
  onlySelected?: string;

  displayImages?: string;
  displayDescriptions?: string;
  displayProducerCode?: string;
  displayEan?: string;
};

export const useUrlQuery = () => {
  const history = useHistory();

  const content: QueryStringContent = history.location.search
    ? QueryString.parse(history.location.search)
    : {};

  const update = (data: Partial<QueryStringContent>) => {
    const searchString = QueryString.stringify({
      ...content,
      ...data,
    });

    history.push(`${history.location.pathname}?${searchString}`);
  }

  return {
    content,
    update,
  };
};
