import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import { IOfferFilters } from "../offer/OfferFilters";
import { useTranslation } from "react-i18next";
import {ProductCategoryType} from "../../api/offer/offer.generated";

const Tree: React.FC<{
  data: ProductCategoryType[];
  onCategoryClick: (categoryId: string) => void;
  categoriesFilter: string[];
}> = ({ data, onCategoryClick, categoriesFilter }) => {
  return (
    <div style={{ marginLeft: "0" }}>
      <ul style={{ listStyle: "none" }}>
        {data.map((tree) => (
          <TreeNode
            categoriesFilter={categoriesFilter}
            onCategoryClick={onCategoryClick}
            node={tree}
          />
        ))}
      </ul>
    </div>
  );
};

const TreeNode: React.FC<{
  node: any;
  onCategoryClick: (categoryId: string) => void;
  categoriesFilter: string[];
}> = ({ node, onCategoryClick, categoriesFilter }) => {
  const [childVisible, setChildVisible] = useState(false);
  const hasChild = !!node.children;
  const isActive = categoriesFilter.includes(node.id);

  return (
    <li style={{ display: "flex", flexDirection: 'column', marginLeft: '-40px' }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          minWidth: "100px",
        }}
      >
        <div onClick={() => onCategoryClick(node.id)}>
          <Typography
            style={{
              padding: "10px 0",
              color: isActive ? "#f50057" : "",
              cursor: "pointer",
            }}
          >
            {node.name}
          </Typography>
        </div>

        {hasChild && (
          <div onClick={() => setChildVisible((v) => !v)}>
            {node.children.length ? (
              <Button style={{ width: "20px" }}>
                {!childVisible ? (
                  <Add
                    style={{
                      color: "red",
                      cursor: "pointer",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                ) : (
                  <Remove
                    style={{
                      color: "red",
                      cursor: "pointer",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                )}
              </Button>
            ) : null}
          </div>
        )}
      </div>
      {hasChild && childVisible && (
        <div>
          <ul style={{paddingLeft: "0"}}>
            <Tree
              categoriesFilter={categoriesFilter}
              onCategoryClick={onCategoryClick}
              data={node.children}
            />
          </ul>
        </div>
      )}
    </li>
  );
};

interface CategoryTreeProps {
  productCategories: any[];
  filters: IOfferFilters;
  onFiltersChange: (filters: IOfferFilters) => void;
}

const CategoryTree: React.FC<CategoryTreeProps> = ({
  productCategories,
  filters,
  onFiltersChange,
}) => {
  const { t } = useTranslation();
  const tree =
    productCategories.map((category) => {
      return {
        ...category,
        children: [],
      };
    }) ?? [];

  const categoriesData: any[] = [];

  tree.forEach((node) => {
    if (!node.parentCategoryId) return categoriesData.push(node);

    const parentIndex = tree.findIndex((el) => el.id === node.parentCategoryId);
    if (!tree[parentIndex].children) {
      return (tree[parentIndex].children = [node]);
    }

    tree[parentIndex].children.push(node);
  });

  const setFilters = (update: Partial<IOfferFilters>) =>
    onFiltersChange({
      ...filters,
      ...update,
    });

  const getAllChildCategoryIds = (catId: string) => {
    const categoryIds: string[] = [];
    let currentCategory;

    let searchedArray = categoriesData;

    do {
      const cat = searchedArray.find(({ id }) => id === catId);
      if (cat) {
        currentCategory = cat;
        break;
      }

      searchedArray = searchedArray.flatMap(({ children }) => children);
    } while (!currentCategory);

    JSON.stringify(currentCategory, (key, value) => {
      if (key === "id") {
        categoryIds.push(value);
      }
      return value;
    });

    return categoryIds;
  };

  const handleCategoryChoose = (categoryId: string) => {
    setFilters({
      ...filters,
      categories: getAllChildCategoryIds(categoryId),
    });
  };

  const handleShowAllCategories = () => {
    setFilters({
      ...filters,
      categories: productCategories.map(({ id }) => id),
    });
  };

  const isAllActive = productCategories.length === filters.categories.length;

  return (
    <section>
      <div>
        <header>
          <Typography variant={"h6"}>{t("categories")}</Typography>
        </header>
        <Typography
          style={{
            marginTop: "15px",
            cursor: "pointer",
            color: isAllActive ? "#f50057" : "",
          }}
          onClick={handleShowAllCategories}
        >
          {t("all")}
        </Typography>
        <Tree
          categoriesFilter={isAllActive ? [] : filters.categories}
          onCategoryClick={handleCategoryChoose}
          data={categoriesData}
        />
      </div>
    </section>
  );
};

export default CategoryTree;
