import React, {useState} from 'react';
import {
  Button,
  Checkbox, FormControl,
  FormControlLabel,
  Grid, InputLabel,
  LinearProgress, MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import EmailValidator from 'email-validator';
import {CompanyTypeEnum, useSaveContactFormMutation} from "../../api/saveContactForm/saveContactForm.generated";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

interface InputItemProps {
  label: string;
  value: string;
  onChange: (value: string) => any;
  disabled: boolean;
  type?: string;
}

const InputItem = (props: InputItemProps) => (
  <Grid item xs={12}>
    <TextField
      type={props.type || 'text'}
      label={props.label}
      fullWidth
      onChange={(e) => props.onChange(e.currentTarget.value)}
      disabled={props.disabled}
    />
  </Grid>
);

export const RegisterForm = () => {
  const [companyName, setCompanyName] = useState('');
  const [taxId, setTaxId] = useState('');
  const [address, setAddress] = useState('');
  const [postCode, setPostCode] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [dropShipping, setDropShipping] = useState(false);
  const [isFormDisabled, setFormDisabled] = useState(false);
  const [companyType, setCompanyType] = useState<CompanyTypeEnum | null>(null);
  const [ isSaved, setSaved ] = useState(false);
  const [ saveContactFormMutation ] = useSaveContactFormMutation();

  const canBeSent: boolean = EmailValidator.validate(email) && companyName !== '' && !!companyType;

  const onSendClick = () => {
    if (!canBeSent) {
      return;
    }

    setFormDisabled(true);

    saveContactFormMutation({
      variables: {
        input: {
          companyName,
          taxId,
          address,
          postCode,
          city,
          email,
          phoneNumber,
          contactPerson,
          dropShipping,
          companyType: companyType as CompanyTypeEnum,
        },
      },
    }).finally(() => {
      setSaved(true);
    });
  };

  return (
    <Grid
      container
      item
      xs={12}
      md={4}
      justify={'center'}
      direction={'column'}
    >
      {isSaved ? (
        <>
          <Typography style={{textAlign: 'center'}}>
            <DoneOutlineIcon
              color={'secondary'}
              fontSize={'large'}
            />
          </Typography>
          <Typography style={{textAlign: 'center'}}>
            Zgłoszenie wysłane poprawnie, skontaktujemy się z Tobą w ciągu 24h!
          </Typography>
        </>
      ) : (
        <>
          <InputItem label={'Nazwa firmy'} value={companyName} onChange={setCompanyName} disabled={isFormDisabled} />
          <InputItem label={'NIP'} value={taxId} onChange={setTaxId} disabled={isFormDisabled} />
          <InputItem label={'Adres'} value={address} onChange={setAddress} disabled={isFormDisabled} />
          <InputItem label={'Kod pocztowy'} value={postCode} onChange={setPostCode} disabled={isFormDisabled} />
          <InputItem label={'Miasto'} value={city} onChange={setCity} disabled={isFormDisabled} />
          <InputItem label={'Adres e-mail'} value={email} onChange={setEmail} type={'email'} disabled={isFormDisabled} />
          <InputItem label={'Telefon kontaktowy'} value={phoneNumber} onChange={setPhoneNumber} disabled={isFormDisabled} />
          <InputItem label={'Osoba kontaktowa'} value={contactPerson} onChange={setContactPerson} disabled={isFormDisabled} />

          <Grid
            item
            xs={12}
            style={{
              paddingTop: 15,
              paddingBottom: 10,
            }}
          >
            <FormControl fullWidth>
              <InputLabel>Rodzaj Działalności</InputLabel>
              <Select
                fullWidth
                label={'Rodzaj Działalności'}
                value={companyType}
                onChange={(e) => setCompanyType(e.target.value as CompanyTypeEnum)}
              >
                <MenuItem value={CompanyTypeEnum.StationaryShop}>Sklep Stacjonarny</MenuItem>
                <MenuItem value={CompanyTypeEnum.OnlineShop}>Sklep Internetowy</MenuItem>
                <MenuItem value={CompanyTypeEnum.Wholesaler}>Hurtownia</MenuItem>
                <MenuItem value={CompanyTypeEnum.Other}>Inna</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <FormControlLabel
              control={<Checkbox checked={dropShipping} onChange={() => setDropShipping(!dropShipping)} />}
              label={'Interesuje mnie Dropshipping'}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              paddingTop: 20,
            }}
          >
            {!isFormDisabled ? (
              <Button
                size={'large'}
                variant={'outlined'}
                color={'secondary'}
                fullWidth
                disabled={!canBeSent}
                onClick={onSendClick}
              >Wyślij</Button>
            ) : (
              <LinearProgress color={'secondary'} />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
