import React, {useState} from 'react';
import {
  Chip,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow, TableSortLabel,
  Tooltip, Typography
} from "@material-ui/core";
import FiberNewIcon from '@material-ui/icons/FiberNew';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {IOfferDisplayConfig, IOfferFilters} from "./OfferFilters";
import {ICart} from "../../hooks/useCart";
import {OfferProductType} from "../../api/offer/offer.generated";
import {DiscountedPrice} from "../discounted-price/DiscountedPrice";
import {ProductImage} from "./ProductImage";
import {ProductQuantityInput} from "../product-quantity-input/ProductQuantityInput";
import {useTranslation} from "react-i18next";

interface IOfferTableProps {
  cart: ICart;
  filters: IOfferFilters;
  displayConfig: IOfferDisplayConfig;
  onCartChange: (productId: string, quantity: number) => any;
  products: Array<Partial<OfferProductType>>;
  allProductsCount: number;
  order: OfferTableOrderEnum;
  setOrder: (order: OfferTableOrderEnum) => any;
  page: number;
  setPage: (page: number) => any;
}

const useStyles = makeStyles({
  tr: {
    '&:hover': {
      background: '#ececec',
      cursor: 'pointer',
    },
  },
});

export enum OfferTableOrderEnum {
  BrimarexCodeAsc,
  BrimarexCodeDesc,
  NameAsc,
  NameDesc,
  PriceAsc,
  PriceDesc,
}

export const OfferTable = (props: IOfferTableProps) => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const classes = useStyles();

  const pageProducts = props.products.slice(props.page * rowsPerPage, (props.page + 1) * rowsPerPage);

  return (
    <Table size={'small'}>
      <TableHead>
        <TableRow>
          <TableCell style={{width: 130}}>
            <TableSortLabel
              active={props.order === OfferTableOrderEnum.BrimarexCodeAsc || props.order === OfferTableOrderEnum.BrimarexCodeDesc}
              direction={props.order === OfferTableOrderEnum.BrimarexCodeAsc ? 'asc' : 'desc'}
              hideSortIcon={true}
              onClick={(e) => props.setOrder(props.order === OfferTableOrderEnum.BrimarexCodeAsc ? OfferTableOrderEnum.BrimarexCodeDesc : OfferTableOrderEnum.BrimarexCodeAsc)}
            >{t('brimarexCode')}</TableSortLabel>
          </TableCell>
          {props.displayConfig.displayProducerCode && (<TableCell style={{width: 130}}>{t('producerCode')}</TableCell>)}
          {props.displayConfig.displayEan && (<TableCell style={{width: 130}}>{t('ean')}</TableCell>)}
          <TableCell style={{width: 10}} />
          <TableCell>
            <TableSortLabel
              active={props.order === OfferTableOrderEnum.NameAsc || props.order === OfferTableOrderEnum.NameDesc}
              direction={props.order === OfferTableOrderEnum.NameAsc ? 'asc' : 'desc'}
              hideSortIcon={true}
              onClick={(e) => props.setOrder(props.order === OfferTableOrderEnum.NameAsc ? OfferTableOrderEnum.NameDesc : OfferTableOrderEnum.NameAsc)}
            >{t('name')}</TableSortLabel>
          </TableCell>
          <TableCell style={{width: 100, textAlign: 'center'}}>
            <TableSortLabel
              active={props.order === OfferTableOrderEnum.PriceAsc || props.order === OfferTableOrderEnum.PriceDesc}
              direction={props.order === OfferTableOrderEnum.PriceAsc ? 'asc' : 'desc'}
              hideSortIcon={true}
              onClick={(e) => props.setOrder(props.order === OfferTableOrderEnum.PriceAsc ? OfferTableOrderEnum.PriceDesc : OfferTableOrderEnum.PriceAsc)}
            >{t('price')}</TableSortLabel>
          </TableCell>
          <TableCell style={{width: 110, textAlign: 'center'}}>
            <TableSortLabel
              active={props.order === OfferTableOrderEnum.PriceAsc || props.order === OfferTableOrderEnum.PriceDesc}
              direction={props.order === OfferTableOrderEnum.PriceAsc ? 'asc' : 'desc'}
              hideSortIcon={true}
              onClick={(e) => props.setOrder(props.order === OfferTableOrderEnum.PriceAsc ? OfferTableOrderEnum.PriceDesc : OfferTableOrderEnum.PriceAsc)}
            >{t('priceWithVat')}</TableSortLabel>
          </TableCell>
          <TableCell style={{width: 100, textAlign: 'center'}}>{t('availableQuantity')}</TableCell>
          <TableCell style={{
            width: 200,
            textAlign: 'center',
          }}>{t('quantity')}</TableCell>
          {props.displayConfig.displayImages && (<TableCell style={{width: 100, textAlign: 'center'}}>{t('image')}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {pageProducts.map((product, index) => {
          return (
            <TableRow
              key={`row-${index}`}
              className={classes.tr}
              onClick={() => {

              }}
            >
              <TableCell>{product.brimarexCode}</TableCell>
              {props.displayConfig.displayProducerCode && (<TableCell>{product.producerCode}</TableCell>)}
              {props.displayConfig.displayEan && (<TableCell>{product.ean}</TableCell>)}
              <TableCell>
                {product.isNew && (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}>
                    <Tooltip title={'Nowość'}>
                      <FiberNewIcon
                        color={'secondary'}
                      />
                    </Tooltip>
                  </div>
                )}
                {product.isTop && (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}>
                    <Tooltip title={'Top'}>
                      <NewReleasesIcon
                        color={'secondary'}
                      />
                    </Tooltip>
                  </div>
                )}
                {product.isPricingPriceFinal && (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}>
                    <Tooltip title={'Promocja'}>
                      <AttachMoneyIcon
                        color={'secondary'}
                      />
                    </Tooltip>
                  </div>
                )}
              </TableCell>
              <TableCell>
                <Typography>{product.name}</Typography>
                {props.displayConfig.displayDescriptions && product.description && product.description.split('\n').map((p, index) => (
                  <Typography variant={'body2'} key={index}>{p}</Typography>
                ))}
              </TableCell>
              <TableCell>
                <DiscountedPrice
                  price={product.price as number}
                  priceDiscounted={product.priceDiscounted as number}
                />
              </TableCell>
              <TableCell>
                <DiscountedPrice
                  price={(product.price as number) * 1.23}
                  priceDiscounted={(product.priceDiscounted as number) * 1.23}
                />
              </TableCell>
              <TableCell style={{textAlign: 'center'}}>
                <Chip
                  color={product.quantity && product.quantity !== '0' ? 'secondary' : undefined}
                  variant={'outlined'}
                  label={product.quantity}
                />
              </TableCell>
              <TableCell>
                <ProductQuantityInput
                  availableQuantity={product.quantity as string}
                  quantityInCart={props.cart.products[product.id as string] || 0}
                  quantityInPackage={product.quantityInPackage || 1}
                  setQuantityInCart={(quantity) => props.onCartChange(product.id as string, quantity)}
                />
              </TableCell>
              {props.displayConfig.displayImages && (
                <TableCell>
                  {props.displayConfig.displayImages ? (() => {
                    if (!product.mainImageKey) {
                      return null;
                    }

                    return (
                      <ProductImage
                        mainImageKey={product.mainImageKey as string}
                        imagesKeys={product.imagesKeys || []}
                        name={product.name as string}
                      />
                    );
                  })() : null}
                </TableCell>
              )}
            </TableRow>
          );
        })}
        <TableRow>
          <TablePagination
            count={props.products.length}
            onChangePage={(e, page) => props.setPage(page)}
            onChangeRowsPerPage={(e) => setRowsPerPage(Number(e.target.value))}
            page={props.page}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count} ${count !== props.allProductsCount ? `(${props.allProductsCount} ${t('overall')})` : ''}`}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
}
