import {setContext} from "@apollo/client/link/context";
import {ApolloClient, concat, HttpLink, InMemoryCache} from "@apollo/client";

const apiUrl = `${process.env.REACT_APP_API_URL}/graphql`;

export const createApolloClient = () => {
  const authMiddleware = setContext(async (operation, { headers }) => {
    return {
      uri: `${apiUrl}?o=${operation.operationName}`,
      headers: headers,
    };
  });

  const httpLink = new HttpLink({
    uri: apiUrl,
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
  })
};
