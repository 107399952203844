import React from 'react';
import {Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import {OfferTypeEnum} from "../../api/offer/offer.generated";
import {formatPrice} from "../../utils/formatPrice";

const useStyles = makeStyles({
  inputBox: {
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  logisticsMinimumItem: {
    padding: 10,
    color: 'red',
  },
});

export interface DeliveryFormState {
  fullName: string;
  address: string;
  postCode: string;
  city: string;
  email: string;
  phoneNumber: string;
}

interface DeliveryFormProps {
  isLogisticsMinimumReached: boolean;
  logisticsMinimumValue: number;
  type: OfferTypeEnum;
  value: DeliveryFormState;
  onChange: (data: DeliveryFormState) => any;
}

export const DeliveryForm = (props: DeliveryFormProps) => {
  const styles = useStyles();

  const updateForm = (field: keyof DeliveryFormState) => (e: React.ChangeEvent<HTMLInputElement>) => props.onChange({
    ...props.value,
    [field]: e.currentTarget.value,
  });

  return (
    <Grid item container xs={12}>
      {props.type === OfferTypeEnum.Dropshipping && (
        <Grid item container xs={12} md={6}>
          <Grid item xs={12} className={styles.inputBox}>
            <Typography variant={'h6'}>Adres Dostawy</Typography>
          </Grid>
          <Grid item xs={12} className={styles.inputBox}>
            <TextField
              fullWidth
              label={'Pełna nazwa (imię i nazwisko lub nazwa firmy)'}
              variant={'outlined'}
              size={'small'}
              value={props.value.fullName}
              onChange={updateForm('fullName')}
            />
          </Grid>
          <Grid item xs={12} className={styles.inputBox}>
            <TextField
              fullWidth
              label={'Adres'}
              variant={'outlined'}
              size={'small'}
              value={props.value.address}
              onChange={updateForm('address')}
            />
          </Grid>
          <Grid item xs={12} md={4} className={styles.inputBox}>
            <TextField
              fullWidth
              label={'Kod pocztowy'}
              variant={'outlined'}
              size={'small'}
              value={props.value.postCode}
              onChange={updateForm('postCode')}
            />
          </Grid>
          <Grid item xs={12} md={8} className={styles.inputBox}>
            <TextField
              fullWidth
              label={'Miejscowość'}
              variant={'outlined'}
              size={'small'}
              value={props.value.city}
              onChange={updateForm('city')}
            />
          </Grid>
          <Grid item xs={12} md={6} className={styles.inputBox}>
            <TextField
              fullWidth
              label={'Adres e-mail'}
              variant={'outlined'}
              size={'small'}
              value={props.value.email}
              onChange={updateForm('email')}
            />
          </Grid>
          <Grid item xs={12} md={6} className={styles.inputBox}>
            <TextField
              fullWidth
              label={'Telefon kontaktowy'}
              variant={'outlined'}
              size={'small'}
              value={props.value.phoneNumber}
              onChange={updateForm('phoneNumber')}
            />
          </Grid>
        </Grid>
      )}

      {props.type === OfferTypeEnum.Standard && !props.isLogisticsMinimumReached ? (
        <Grid item xs={12} className={styles.logisticsMinimumItem}>
          <Typography>
            Minimum logistyczne w wysokości {formatPrice(props.logisticsMinimumValue)} nie zostało osiągnięte. Mogą zostać doliczone opłaty transportowe. Dokładny koszt zostanie podany przez Opiekuna po zweryfikowaniu zapytania.
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};
