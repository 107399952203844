export const pl = {
  brimarexCode: 'Kod Brimarex',
  producerCode: 'Kod Producenta',
  ean: 'EAN',
  name: 'Nazwa',
  price: 'Cena',
  priceWithVat: 'Cena Brutto',
  availableQuantity: 'Dostępna Ilość',
  quantity: 'Ilość',
  image: 'Zdjęcie',
  quantityInPackage: 'Sztuk w opakowaniu',
  producer: 'Producent',
  additionalFilters: 'Dodatkowe',
  displaySettings: 'Widok',
  onlyAvailable: 'Tylko Dostępne',
  onlyNew: 'Tylko Nowości',
  onlyTop: 'Tylko Top',
  onlyPromo: 'Tylko Promocje',
  onlySelected: 'Tylko Wybrane',
  displayImages: 'Pokaż Zdjęcia',
  displayDescriptions: 'Pokaż Opisy',
  displayProducerCode: 'Pokaż Kod Producenta',
  displayEan: 'Pokaż EAN',
  order: 'Zamówienie',
  belowYouCanFindOrderSummary: 'Poniżej znajdziesz podsumowanie swojego zamówienia.',
  afterOrderCreationTraderWillContactYou: 'Po wpłynięciu zamówienia, Twój opiekun niezwłocznie się z Tobą skontaktuje w celu potwierdzenia szczegółów.',
  savingOrder: 'Trwa wysyłanie zamówienia...',
  orderSaved: 'Zamówienie wysłane! Pamiętaj, że możesz z tej oferty skorzystać wiele razy i złożyć wiele zamówień.',
  value: 'Wartość',
  valueWithVat: 'Wartość Brutto',
  selectedQuantityHigherThanAvailable: 'Wybrano większą ilość niż dostępna.',
  commentsToOrder: 'Uwagi do zamówienia',
  createOrder: 'Złóż zamówienie',
  rowsPerPage: 'Ilość na stronie',
  overall: 'wszystkich',
  of: 'z',
  search: 'Szukaj',
  all: 'Wszystkie',
  categories: 'Kategorie'
};
