import React from 'react';
import CookieConsent from "react-cookie-consent";

export const CookieInfo = () => {
  return (
    <CookieConsent
      buttonText={'Akceptuje'}
      style={{
        background: "#2c2c2c",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
      }}
      buttonStyle={{
        color: "#ffffff",
        fontSize: "13px",
        backgroundColor: '#ff0000',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
      }}
    >Ta strona wykorzystuje pliki cookies. Korzystając z niej, wyrażasz na to zgodę.</CookieConsent>
  );
};
