import React from 'react';
import {Grid, makeStyles} from "@material-ui/core";
import {formatPrice} from "../../utils/formatPrice";

const useStyles = makeStyles({
  crossedPrice: {
    textDecoration: 'line-through',
    textAlign: 'center',
  },
  standardPrice: {
    textAlign: 'center',
  },
});

interface DiscountedPriceProps {
  price: number;
  priceDiscounted: number;
}

export const DiscountedPrice = ({ price, priceDiscounted }: DiscountedPriceProps) => {
  const styles = useStyles();

  return (
    <Grid container>
      {price === priceDiscounted ? null : (
        <Grid item xs={12} className={styles.crossedPrice}>
          {price ? formatPrice(price) : null}
        </Grid>
      )}
      <Grid item xs={12} className={styles.standardPrice}>
        {priceDiscounted ? formatPrice(priceDiscounted) : null}
      </Grid>
    </Grid>
  );
};
