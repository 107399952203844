import React from 'react';
import {Grid, makeStyles, Typography} from "@material-ui/core";
import Logo from '../../logo.svg';
import {RegisterForm} from "../register-form/RegisterForm";
import {CookieInfo} from "../../components/cookie-info/CookieInfo";

const useStyles = makeStyles({
  textItem: {
    padding: 10,
  },
});

export const Home = () => {
  const styles = useStyles();

  return (
    <Grid
      container
      justify={'center'}
      direction={'column'}
      alignItems={'center'}
    >
      <Grid item xs={12} sm={4} md={2} className={styles.textItem}>
        <img
          src={Logo}
          alt={'Logo Brimarex'}
          style={{
            width: '100%',
          }}
        />
      </Grid>
      <Grid item xs={12} className={styles.textItem}>
        <Typography>Witamy na platformie B2B Brimarex</Typography>
      </Grid>
      <Grid item xs={12} className={styles.textItem}>
        <Typography>W celu otrzymania dostępu do oferty wypełnij poniższy formularz</Typography>
      </Grid>
      <RegisterForm />
      <CookieInfo />
    </Grid>
  );
};
