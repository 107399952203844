import {Button, InputBase, Paper} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

interface SearchInputProps {
  defaultValue: string;
  disabled: boolean;
  onSearchClick: (searchPhrase: string) => any;
}

export const SearchInput: React.FC<SearchInputProps> = ({defaultValue, disabled, onSearchClick}) => {
    const {t} = useTranslation()
    const [searchPhrase, setSearchPhrase] = useState<string>(defaultValue);
    return (
        <Paper
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '5px 15px 5px 15px'}}
        >
            <InputBase
                style={{width: '100%'}}
                placeholder="Szukaj"
                value={searchPhrase}
                onChange={(newValue) => setSearchPhrase(newValue.target.value)}
            />
            <Button
                color={'secondary'}
                variant={'contained'}
                onClick={() => onSearchClick(searchPhrase)}
                disabled={disabled}
              >
                {t('search')}
                <Search style={{width: '20px', paddingLeft: '5px', marginTop: '2px'}}/>
            </Button>
        </Paper>
    )
 }
