import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import reportWebVitals from './reportWebVitals';
import {Offer} from "./components/offer/Offer";
import {ApolloProvider} from "@apollo/client";
import {createApolloClient} from "./apollo/apollo-client";
import {Home} from "./pages/home/Home";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={createApolloClient()}>
      <BrowserRouter>
        <Switch>
          <Route path={'/'} exact>
            <Home />
          </Route>
          <Route path={'/offer'} exact>
            <Offer />
          </Route>
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
ReactGA.initialize('G-H9H72S0WRH');
ReactGA.pageview(window.location.pathname + window.location.search);
