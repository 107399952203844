import * as Types from '../../interfaces/api.types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum CompanyTypeEnum {
  StationaryShop = 'StationaryShop',
  OnlineShop = 'OnlineShop',
  Wholesaler = 'Wholesaler',
  Other = 'Other'
}

export type ContactFormInputType = {
  companyName: Scalars['String'];
  taxId: Scalars['String'];
  address: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  contactPerson: Scalars['String'];
  dropShipping: Scalars['Boolean'];
  companyType: CompanyTypeEnum;
};

export enum ContactFormStatusEnum {
  New = 'New',
  Opened = 'Opened',
  Closed = 'Closed'
}

export type ContactFormType = {
  __typename?: 'ContactFormType';
  id: Scalars['String'];
  companyName: Scalars['String'];
  taxId: Scalars['String'];
  address: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  contactPerson: Scalars['String'];
  createdAt: Scalars['String'];
  dropShipping: Scalars['Boolean'];
  companyType: CompanyTypeEnum;
  status: ContactFormStatusEnum;
};

export type CreatePurchaseRequestInput = {
  offerId: Scalars['String'];
  code: Scalars['String'];
  additionalInfo?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<DeliveryAddressInputType>;
};

export type CreatePurchaseRequestResultType = {
  __typename?: 'CreatePurchaseRequestResultType';
  purchaseRequestId: Scalars['String'];
};

export type DeliveryAddressInputType = {
  fullName: Scalars['String'];
  address: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ImageUploadCredentialsType = {
  __typename?: 'ImageUploadCredentialsType';
  url: Scalars['String'];
  fields: Scalars['String'];
  fileKey: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  saveContactForm: Scalars['String'];
  updateContactFormStatus: Scalars['String'];
  signImageUploadCredentials: ImageUploadCredentialsType;
  createOffer: OfferType;
  updateOffer: OfferType;
  deleteOffer: Scalars['String'];
  putProductInCart: OfferCartType;
  appendOfferHistoryLog: Scalars['String'];
  createProduct: ProductType;
  updateProduct: ProductType;
  updatePrices: Scalars['Float'];
  updateQuantities: Scalars['Float'];
  createCategory: Scalars['String'];
  updateCategory: Scalars['String'];
  deleteCategory: Scalars['String'];
  createPurchaseRequest: CreatePurchaseRequestResultType;
  updatePurchaseRequestStatus: PurchaseRequestType;
  appendUserRole: UserType;
  deleteUserRole: UserType;
};


export type MutationSaveContactFormArgs = {
  input: ContactFormInputType;
};


export type MutationUpdateContactFormStatusArgs = {
  status: ContactFormStatusEnum;
  contactFormId: Scalars['String'];
};


export type MutationSignImageUploadCredentialsArgs = {
  fileName: Scalars['String'];
};


export type MutationUpdateOfferArgs = {
  input: OfferUpdateInputType;
  id: Scalars['String'];
};


export type MutationDeleteOfferArgs = {
  id: Scalars['String'];
};


export type MutationPutProductInCartArgs = {
  quantity: Scalars['Int'];
  productId: Scalars['String'];
  code: Scalars['String'];
  offerId: Scalars['String'];
};


export type MutationAppendOfferHistoryLogArgs = {
  logType: OfferHistoryLogTypeEnum;
  code: Scalars['String'];
  offerId: Scalars['String'];
};


export type MutationCreateProductArgs = {
  input: ProductInputType;
};


export type MutationUpdateProductArgs = {
  update: ProductUpdateInputType;
  productId: Scalars['String'];
};


export type MutationCreateCategoryArgs = {
  parentCategoryId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationUpdateCategoryArgs = {
  parentCategoryId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  categoryId: Scalars['String'];
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['String'];
};


export type MutationCreatePurchaseRequestArgs = {
  input: CreatePurchaseRequestInput;
};


export type MutationUpdatePurchaseRequestStatusArgs = {
  status: PurchaseRequestStatusEnum;
  id: Scalars['String'];
};


export type MutationAppendUserRoleArgs = {
  role: UserRoleEnum;
  userId: Scalars['String'];
};


export type MutationDeleteUserRoleArgs = {
  role: UserRoleEnum;
  userId: Scalars['String'];
};

export type OfferCartProductType = {
  __typename?: 'OfferCartProductType';
  productId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type OfferCartType = {
  __typename?: 'OfferCartType';
  products: Array<OfferCartProductType>;
};

export type OfferHistoryLogType = {
  __typename?: 'OfferHistoryLogType';
  id: Scalars['String'];
  offerId: Scalars['String'];
  logType: OfferHistoryLogTypeEnum;
  createdAt: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  offer: OfferType;
};

export enum OfferHistoryLogTypeEnum {
  Open = 'Open',
  Close = 'Close'
}

export type OfferProductType = {
  __typename?: 'OfferProductType';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  brimarexCode: Scalars['String'];
  producerCode: Scalars['String'];
  ean: Scalars['String'];
  categoryId: Scalars['String'];
  active: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  isTop: Scalars['Boolean'];
  isPricingPriceFinal: Scalars['Boolean'];
  pcn?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  imagesKeys: Array<Scalars['String']>;
  mainImageKey?: Maybe<Scalars['String']>;
  pricesQuantity: Scalars['Int'];
  quantity?: Maybe<Scalars['String']>;
  quantityInPackage: Scalars['Int'];
  price: Scalars['Int'];
  priceDiscounted: Scalars['Int'];
};

export enum OfferStatusEnum {
  Draft = 'Draft',
  Active = 'Active',
  Finished = 'Finished'
}

export type OfferType = {
  __typename?: 'OfferType';
  id: Scalars['String'];
  code: Scalars['String'];
  customerName: Scalars['String'];
  customerDiscount: Scalars['Float'];
  priceSource: Scalars['String'];
  status: OfferStatusEnum;
  traderId: Scalars['String'];
  createdAt: Scalars['String'];
  cartValue?: Maybe<Scalars['Int']>;
  type: OfferTypeEnum;
  trader: UserType;
  cart: OfferCartType;
  history: Array<OfferHistoryLogType>;
  products: Array<OfferProductType>;
  purchaseRequests: Array<PurchaseRequestType>;
};


export type OfferTypeProductsArgs = {
  searchPhrase?: Maybe<Scalars['String']>;
};

export enum OfferTypeEnum {
  Standard = 'Standard',
  Dropshipping = 'Dropshipping'
}

export type OfferUpdateInputType = {
  customerName?: Maybe<Scalars['String']>;
  customerDiscount?: Maybe<Scalars['Float']>;
  priceSource?: Maybe<Scalars['String']>;
  status?: Maybe<OfferStatusEnum>;
  traderId?: Maybe<Scalars['String']>;
  type?: Maybe<OfferTypeEnum>;
};

export type ProductCategoryType = {
  __typename?: 'ProductCategoryType';
  id: Scalars['String'];
  name: Scalars['String'];
  parentCategoryId?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  canBeDeleted: Scalars['Boolean'];
};

export type ProductInputType = {
  name: Scalars['String'];
  description: Scalars['String'];
  brimarexCode: Scalars['String'];
  producerCode: Scalars['String'];
  ean: Scalars['String'];
  categoryId: Scalars['String'];
  active: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  isTop: Scalars['Boolean'];
  isPricingPriceFinal: Scalars['Boolean'];
  pcn?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  quantityInPackage?: Maybe<Scalars['Int']>;
  imagesKeys: Array<Scalars['String']>;
  mainImageKey?: Maybe<Scalars['String']>;
};

export type ProductType = {
  __typename?: 'ProductType';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  brimarexCode: Scalars['String'];
  producerCode: Scalars['String'];
  ean: Scalars['String'];
  categoryId: Scalars['String'];
  active: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  isTop: Scalars['Boolean'];
  isPricingPriceFinal: Scalars['Boolean'];
  pcn?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  imagesKeys: Array<Scalars['String']>;
  mainImageKey?: Maybe<Scalars['String']>;
  pricesQuantity: Scalars['Int'];
  quantity?: Maybe<Scalars['String']>;
  quantityInPackage: Scalars['Int'];
};

export type ProductUpdateInputType = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brimarexCode?: Maybe<Scalars['String']>;
  producerCode?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  isTop?: Maybe<Scalars['Boolean']>;
  isPricingPriceFinal?: Maybe<Scalars['Boolean']>;
  pcn?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  quantityInPackage?: Maybe<Scalars['Int']>;
  imagesKeys?: Maybe<Array<Scalars['String']>>;
  mainImageKey?: Maybe<Scalars['String']>;
};

export type ProductsQueryResultType = {
  __typename?: 'ProductsQueryResultType';
  overallCount: Scalars['Int'];
  products: Array<ProductType>;
};

export type PurchaseRequestProductType = {
  __typename?: 'PurchaseRequestProductType';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  brimarexCode: Scalars['String'];
  producerCode: Scalars['String'];
  ean: Scalars['String'];
  categoryId: Scalars['String'];
  active: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  isTop: Scalars['Boolean'];
  isPricingPriceFinal: Scalars['Boolean'];
  pcn?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  imagesKeys: Array<Scalars['String']>;
  mainImageKey?: Maybe<Scalars['String']>;
  pricesQuantity: Scalars['Int'];
  quantity?: Maybe<Scalars['String']>;
  quantityInPackage: Scalars['Int'];
  requestedQuantity: Scalars['Int'];
  requestedPrice: Scalars['Int'];
};

export enum PurchaseRequestStatusEnum {
  New = 'New',
  Opened = 'Opened',
  Closed = 'Closed'
}

export type PurchaseRequestType = {
  __typename?: 'PurchaseRequestType';
  id: Scalars['String'];
  offerId: Scalars['String'];
  status: PurchaseRequestStatusEnum;
  createdAt: Scalars['String'];
  additionalInfo?: Maybe<Scalars['String']>;
  products: Array<PurchaseRequestProductType>;
  offer: OfferType;
};

export type Query = {
  __typename?: 'Query';
  contactForms: Array<ContactFormType>;
  offer: OfferType;
  offers: Array<OfferType>;
  combinedOffersHistory: Array<OfferHistoryLogType>;
  priceSources: Array<Scalars['String']>;
  products: ProductsQueryResultType;
  product: ProductType;
  productCategories: Array<ProductCategoryType>;
  purchaseRequest: PurchaseRequestType;
  purchaseRequests: Array<PurchaseRequestType>;
  me: UserType;
  users: Array<UserType>;
};


export type QueryOfferArgs = {
  code?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type QueryCombinedOffersHistoryArgs = {
  olderThan?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryProductsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  isTop?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryProductArgs = {
  id: Scalars['String'];
};


export type QueryPurchaseRequestArgs = {
  id: Scalars['String'];
};

export enum UserRoleEnum {
  Admin = 'Admin',
  Trader = 'Trader'
}

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  roles: Array<UserRoleEnum>;
};

export type OfferQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  code: Types.Scalars['String'];
  searchPhrase?: Types.Maybe<Types.Scalars['String']>;
}>;


export type OfferQuery = (
  { __typename?: 'Query' }
  & { offer: (
    { __typename?: 'OfferType' }
    & Pick<Types.OfferType, 'id' | 'type' | 'customerName' | 'customerDiscount'>
    & { products: Array<(
      { __typename?: 'OfferProductType' }
      & Pick<Types.OfferProductType, 'id' | 'brimarexCode' | 'producerCode' | 'ean' | 'isNew' | 'isTop' | 'name' | 'price' | 'priceDiscounted' | 'isPricingPriceFinal' | 'quantity' | 'imagesKeys' | 'mainImageKey' | 'categoryId' | 'description' | 'quantityInPackage'>
    )>, cart: (
      { __typename?: 'OfferCartType' }
      & { products: Array<(
        { __typename?: 'OfferCartProductType' }
        & Pick<Types.OfferCartProductType, 'productId' | 'quantity'>
      )> }
    ) }
  ), productCategories: Array<(
    { __typename?: 'ProductCategoryType' }
    & Pick<Types.ProductCategoryType, 'id' | 'name' | 'parentCategoryId'>
  )> }
);


export const OfferDocument = gql`
    query offer($id: String!, $code: String!, $searchPhrase: String) {
  offer(id: $id, code: $code) {
    id
    type
    customerName
    customerDiscount
    products(searchPhrase: $searchPhrase) {
      id
      brimarexCode
      producerCode
      ean
      isNew
      isTop
      name
      price
      priceDiscounted
      isPricingPriceFinal
      quantity
      imagesKeys
      mainImageKey
      categoryId
      description
      quantityInPackage
    }
    cart {
      products {
        productId
        quantity
      }
    }
  }
  productCategories {
    id
    name
    parentCategoryId
  }
}
    `;

/**
 * __useOfferQuery__
 *
 * To run a query within a React component, call `useOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *      searchPhrase: // value for 'searchPhrase'
 *   },
 * });
 */
export function useOfferQuery(baseOptions: Apollo.QueryHookOptions<OfferQuery, OfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OfferQuery, OfferQueryVariables>(OfferDocument, options);
      }
export function useOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfferQuery, OfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OfferQuery, OfferQueryVariables>(OfferDocument, options);
        }
export type OfferQueryHookResult = ReturnType<typeof useOfferQuery>;
export type OfferLazyQueryHookResult = ReturnType<typeof useOfferLazyQuery>;
export type OfferQueryResult = Apollo.QueryResult<OfferQuery, OfferQueryVariables>;
export function refetchOfferQuery(variables?: OfferQueryVariables) {
      return { query: OfferDocument, variables: variables }
    }