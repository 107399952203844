import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {ProductCategoryType} from "../../api/offer/offer.generated";

export interface IOfferFilters {
  categories: string[];
  onlyAvailable: boolean;
  onlyNew: boolean;
  onlyTop: boolean;
  onlyPromo: boolean;
  onlySelected: boolean;
}

export interface IOfferDisplayConfig {
  displayImages: boolean;
  displayDescriptions: boolean;
  displayProducerCode: boolean;
  displayEan: boolean;
}

interface OfferFiltersProps {
  productCategories: ProductCategoryType[];
  filters: IOfferFilters;
  onFiltersChange: (filters: IOfferFilters) => any;
  displayConfig: IOfferDisplayConfig;
  onDisplayConfigChange: (config: IOfferDisplayConfig) => any;
}

export const OfferFilters = (props: OfferFiltersProps) => {
  const { t } = useTranslation();

  const setFilters = (update: Partial<IOfferFilters>) =>
    props.onFiltersChange({
      ...props.filters,
      ...update,
    });

  const setDisplayConfig = (update: Partial<IOfferDisplayConfig>) =>
    props.onDisplayConfigChange({
      ...props.displayConfig,
      ...update,
    });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={"h6"}>{t("additionalFilters")}</Typography>
      </Grid>

      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.filters.onlyAvailable}
                onChange={() =>
                  setFilters({ onlyAvailable: !props.filters.onlyAvailable })
                }
              />
            }
            label={t("onlyAvailable")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.filters.onlyNew}
                onChange={() => setFilters({ onlyNew: !props.filters.onlyNew })}
              />
            }
            label={t("onlyNew")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.filters.onlyTop}
                onChange={() => setFilters({ onlyTop: !props.filters.onlyTop })}
              />
            }
            label={t("onlyTop")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.filters.onlyPromo}
                onChange={() =>
                  setFilters({ onlyPromo: !props.filters.onlyPromo })
                }
              />
            }
            label={t("onlyPromo")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.filters.onlySelected}
                onChange={() =>
                  setFilters({ onlySelected: !props.filters.onlySelected })
                }
              />
            }
            label={t("onlySelected")}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <Typography variant={"h6"}>{t("displaySettings")}</Typography>
      </Grid>

      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.displayConfig.displayImages}
                onChange={() =>
                  setDisplayConfig({
                    displayImages: !props.displayConfig.displayImages,
                  })
                }
              />
            }
            label={t("displayImages")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.displayConfig.displayDescriptions}
                onChange={() =>
                  setDisplayConfig({
                    displayDescriptions:
                      !props.displayConfig.displayDescriptions,
                  })
                }
              />
            }
            label={t("displayDescriptions")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.displayConfig.displayProducerCode}
                onChange={() =>
                  setDisplayConfig({
                    displayProducerCode:
                      !props.displayConfig.displayProducerCode,
                  })
                }
              />
            }
            label={t("displayProducerCode")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.displayConfig.displayEan}
                onChange={() =>
                  setDisplayConfig({
                    displayEan: !props.displayConfig.displayEan,
                  })
                }
              />
            }
            label={t("displayEan")}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};
