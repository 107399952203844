import React from 'react';
import {Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useUrlQuery} from "../../hooks/useUrlQuery";

export const ChangeLanguageButton = () => {
  const { i18n } = useTranslation();
  const urlQuery = useUrlQuery();

  if (urlQuery.content.lang !== undefined && urlQuery.content.lang !== i18n.language) {
    i18n.changeLanguage(i18n.language === 'pl' ? 'en' : 'pl');
  }

  const changeLang = () => {
    urlQuery.update({
      lang: i18n.language === 'pl' ? 'en' : 'pl',
    });

    i18n.changeLanguage(i18n.language === 'pl' ? 'en' : 'pl');
  };

  return (
    <Button
      variant={'outlined'}
      size={'small'}
      style={{
        marginRight: 20,
      }}
      onClick={changeLang}
    >{i18n.language === 'pl' ? 'Switch to English' : 'Switch to Polish'}</Button>
  );
};
